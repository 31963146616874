import React from 'react';
import error from './images/error.png';

class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {

        if (this.state.hasError) {
            const style = {
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '150px',
                transform: 'translate(-50%, -50%)'
            }
            // You can render any custom fallback UI
            return <img style={style} src={error} alt='Error' />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;