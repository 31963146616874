import axios from "axios";
import { HOST } from "@/host";
import { message } from "antd";

const ACTION_FETCH_EA_LIVE_SUCCESS = "EA/FETCH_EA_LIVE_SUCCESS";
const ACTION_CHANGE_STRATEGY_NAME_SUCCESS =
  "EA/ACTION_CHANGE_STRATEGY_NAME_SUCCESS";


const fetchEAAction = (type, result) => ({
  type,
  result,
});

// --------------- condition ----------------------
export const fetchSrategies = () => async (dispatch) => {
  getStrategies(dispatch);
};

export const getStrategyLabels = (callback) => async (dispatch) => {
  const response = await axios.get(HOST + `/api/live/strategyLabels`);
  callback && callback(response.data);
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, { labels: response.data })
  );
};

// handle findAllStrategies api
const getStrategies = async (dispatch) => {
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, { loading: true })
  );
  const response = await axios.get(HOST + `/api/live/getStartedStrategies`);
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, { strategyList: response.data, loading: false })
  );
};

export const editLiveConf = (strategyConfigVo) => async (dispatch) => {
  await axios.post(HOST + `/api/live/updateLiveConf`, { ...strategyConfigVo });
  message.success("成功编辑配置！", 1);
  getStrategies(dispatch);
};

export const startStrategy = (confId) => {
  return (dispatch) => {
    return axios
      .post(
        HOST + `/api/live/startStrategy?confId=${confId}`,
        {},
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        message.success("成功启动策略！", 1);
        getStrategies(dispatch);
      });
    // .catch(err => {
    //     debugger
    //     message.error(err && err.response && err.response.data && err.response.data.message ? err.response.data.message : '请求错误！')
    // })
  };
};

export const stopStrategy = (confId) => {
  return (dispatch) => {
    return axios
      .post(
        HOST + `/api/live/stopStrategy?confId=${confId}`,
        {},
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        message.success("成功关闭策略！", 1);
        getStrategies(dispatch);
      });
    // .catch(e => {
    //     message.error(e.response.data.message, 1)
    // })
  };
};

// --------------- order ----------------------
export const findAllOrders = () => async (dispatch) => {
  const response = await axios.get(HOST + `/api/live/findAllOrders`);
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, { allOrderList: response.data })
  );
};

export const getAllOrders = (confId) => async (dispatch) => {
  dispatch(fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, { orderLoading: true }));
  const response = await axios.get(HOST + `/api/live/getAllOrders`, {
    params: { confId },
  });
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, {
      orderList: response.data,
      orderLoading: false,
    })
  );
};

// --------------- condition ----------------------
export const getConditions = (confId) => async (dispatch) => {
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, { conditionLoading: true })
  );
  const response = await axios.get(HOST + `/api/live/getConditions`, {
    params: { confId },
  });
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, {
      conditionList: response.data,
      conditionLoading: false,
    })
  );
};

export const deleteCondition = ({ id, confId }) => async (dispatch) => {
  await axios.post(HOST + `/api/live/deleteCondition?id=${id}`, {});
  message.success("成功删除condition！", 1);
  const response = await axios.get(HOST + `/api/live/getConditions`, {
    params: { confId },
  });
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, {
      conditionList: response.data,
    })
  );
};

export const addCondition = ({ condition, confId }) => async (dispatch) => {
  await axios.post(HOST + `/api/live/addCondition?confId=${confId}`, {
    ...condition,
  });
  message.success("成功添加condition！", 1);
  const response = await axios.get(HOST + `/api/live/getConditions`, {
    params: { confId },
  });
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, {
      conditionList: response.data,
    })
  );
};

export const updateCondition = ({ condition, confId }) => async (dispatch) => {
  await axios.post(HOST + `/api/live/updateCondition?confId=${confId}`, {
    ...condition,
  });
  message.success("成功编辑condition！", 1);
  const response = await axios.get(HOST + `/api/live/getConditions`, {
    params: { confId },
  });
  dispatch(
    fetchEAAction(ACTION_FETCH_EA_LIVE_SUCCESS, {
      conditionList: response.data,
    })
  );
};

const defaultValue = {
  loading: false,
  orderLoading: false,
  conditionLoading: false,
  strategyName: "",
  labels: [],
  strategyList: [],
  orderList: { history: [], live: [] },
  conditionList: [],
};

const reducer = (state = defaultValue, action) => {
  switch (action.type) {
    case ACTION_FETCH_EA_LIVE_SUCCESS:
      return { ...state, ...action.result };
    case ACTION_CHANGE_STRATEGY_NAME_SUCCESS:
      return { ...state, strategyName: action.name };
    default:
      return state;
  }
};

export default reducer