import { useState, useEffect } from "react";
import { Form, InputNumber, Button, Select } from "antd";
import { getPipCount, getCurrentPrice } from "@/api/common";
import { getInstrumentsWithMinAmount } from "@/api/ea";

const { Option } = Select;

const PipCountForm = () => {
  const [form] = Form.useForm();
  const [money, setMoney] = useState("-");
  const [pipCount, setPipCount] = useState("-");
  const [instrumentMap, setInstrumentMap] = useState({});
  const [instrumentList, setInstrumentList] = useState([]);

  useEffect(() => {
    fetchInstruments();
  }, []);

  const fetchInstruments = async () => {
    const { data } = await getInstrumentsWithMinAmount();
    const labels = Object.keys(data);
    setInstrumentList(labels);
    setInstrumentMap(data);
  };

  const handleSubmit = async (e) => {
    const values = await form.validateFields();
    getPipCount(values).then((response) => {
      const { data } = response;
      setMoney(data.money);
      setPipCount(data.pipCount);
    });
  };

  const handleInstrumentChange = async (value) => {
    const { data } = await getCurrentPrice(value);
    form.setFieldsValue({
      amount: instrumentMap[value],
      from: data
    });
    
  };

  return (
    <>
      <h4>外汇计算器（快捷键：ctrl+Q）</h4>
      <Form form={form} layout="inline" onFinish={handleSubmit}>
          <Form.Item
            label="instrument"
            name="instrument"
            initialvalue=""
            rules={[
              {
                required: true,
                message: "请选择instrument",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 180 }}
              optionFilterProp="children"
              placeholder="请选择instrument"
              initialvalue={instrumentList[0]}
              onChange={handleInstrumentChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {instrumentList.map((instrument) => (
                <Option value={instrument} key={instrument}>
                  {instrument}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="amount" name="amount" initialvalue="">
            <InputNumber style={{ width: 130 }} min={0} />
          </Form.Item>
          <Form.Item
            label="from"
            name="from"
            initialvalue=""
            rules={[{ required: true, message: "请输入from" }]}
          >
            <InputNumber style={{ width: 130 }} min={0} />
          </Form.Item>
          <Form.Item
            label="to"
            name="to"
            initialvalue=""
            rules={[{ required: true, message: "请输入To" }]}
          >
            <InputNumber style={{ width: 130 }} min={0} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              计算PipCount
            </Button>
          </Form.Item>
          <Form.Item label="结果：">
            <b>
              money: {money}&nbsp; &nbsp;&nbsp;pipCount:{pipCount}
            </b>
          </Form.Item>
        </Form>
    </>
  );
};

export default PipCountForm;
