/**
 * Created by yelan on 2018/9/4.
 */
import axios from "axios";
import { HOST } from "@/host";
import { convertObjToQuery } from "@/util/Helper";

// stock and strategy
let API_GET_STOCK = HOST + "/api/findStocks";

export const fetchStocks = (keyword) => {
  return axios.get(API_GET_STOCK, {
    params: { keyword: keyword },
  });
};

export const getProfile = () => {
  return axios.get(HOST + "/api/profile/get", { params: {} });
};

export const getDeployTime = () => {
  return axios.get(HOST + "/api/profile/getDeployTime", {
    params: {},
  });
};

// chart
export const getEAChart = () => {
  return axios.get(HOST + "/api/live/getEquityChart", {
    params: {},
  });
};

export const getPercentChart = () => {
  return axios.get(HOST + "/api/live/getPercentChart", {
    params: {},
  });
};

// calc
export const getPipCount = (data) => {
  const str = convertObjToQuery(data);
  return axios.get(HOST + `/api/live/getPipCount?${str}`, {
    params: {},
  });
};

export const getDollar = ({ amount }) => {
  return axios.get(HOST + `/api/live/getDollar?amount=${amount}`, {
    params: {},
  });
};

export const getJfexAmount = ({ amountInDollar }) => {
  return axios.get(
    HOST + `/api/live/getJfexAmount?amountInDollar=${amountInDollar}`
  );
};

export const pointToPrice = ({ pip, instrument }) => {
  return axios.get(
    HOST + `/api/live/pointToPrice?instrument=${instrument}&pip=${pip}`
  );
};

export const getInstrumentInfo = ({ instrument }) => {
  return axios.get(HOST + `/api/live/instrumentInfo?instrument=${instrument}`);
};

export const calcCurrentPrice = (values) => {
  return axios.post(HOST + `/api/calc/getPrice`, values);
};

export const getCurrentPrice = (instrument) => {
  return axios.get(HOST + `/api/live/getCurrentPrice?instrument=${instrument}`);
}

// logout
export const logout = () => {
  return axios.post(HOST + `/logout`);
};

// user
export const getUser = () => {
  return axios.get(HOST + `/api/profile/user`);
};

// get menus
export const getNavList = () => {
  return axios.get(HOST + "/api/profile/nav/list");
};
