import axios from "axios";
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_FAILURE } from "MyConstants";
import { HOST } from "@/host";
import { showLoading, hideLoading } from "react-redux-loading-bar";

const ACTION_FETCH_NOTE_SUCCESS = "NOTE/FETCH_NOTE_SUCCESS";
const ACTION_FETCH_NOTE_FAILURE = "NOTE/FETCH_NOTE_FAILURE";

const config = {
  headers: {
    "content-type": "application/json",
  },
};

const fetchNoteAction = (type, result) => ({
  type,
  result,
});

export const fetchNotes = ({ year, month }) => async (dispatch) => {
  dispatch(showLoading());
  const { data } = await axios.get(HOST + "/api/findNotesInMonth", {
    params: { year, month },
  });
  dispatch(
    fetchNoteAction(ACTION_FETCH_NOTE_SUCCESS, { notes: data, year, month })
  );
  dispatch(hideLoading());
};

export const createNotes = (data) => async (dispatch) => {
  const { year, month } = data;
  dispatch(showLoading());
  await axios.post(HOST + "/api/createNote", data, config);
  await dispatch(fetchNotes({ year, month }));
  dispatch(hideLoading());
};

export const updateNotes = (data, callback) => async (dispatch) => {
  const { year, month } = data;
  dispatch(showLoading());
  await axios.post(HOST + "/api/updateNote", data, config);
  await dispatch(fetchNotes({ year, month }));
  dispatch(hideLoading());
};

const date = new Date();
const initData = {
  status: STATUS_LOADING,
  notes: [],
  year: date.getFullYear(),
  month: date.getMonth() + 1,
};
const reducer = (state = initData, action) => {
  switch (action.type) {
    case ACTION_FETCH_NOTE_SUCCESS:
      return { ...state, status: STATUS_SUCCESS, ...action.result };
    case ACTION_FETCH_NOTE_FAILURE:
      return { ...state, status: STATUS_FAILURE };
    default:
      return state;
  }
};

export default reducer