import Cookies from "universal-cookie";
import moment from "moment";

const cookies = new Cookies();
export const getSec = () => {
  const pwd = cookies.get("sec");
  return pwd ? window.atob(pwd) : "";
};

export const setPwd = (pwd) => {
  cookies.set("sec", window.btoa(pwd));
};

export const setKey = (array, key) => {
  return array.map((item, index) => {
    if (key) {
      item.key = item[key];
    } else {
      item.key = index;
    }

    return item;
  });
};

/**
 * 下载文件
 *
 * @param {} url
 * @param {*} fileName
 */
export const downloadFile = (url, fileName) => {
  try {
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName;
    const a = document.body.appendChild(element);
    a.click();
    document.body.removeChild(element);
  } catch (e) { }
};

/**
 * 对象转为请求参数格式
 * @param {*} data
 */
export const convertObjToQuery = (data) => {
  var _result = [];
  for (var key in data) {
    var value = data[key];
    if (value !== null && value !== undefined && value !== '') {
      _result.push(key + "=" + value);
    }
  }
  return _result.join("&");
};

/**
 * 判断是否是PC
 * @returns 
 */
export const isPC = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

/**
 * Format time
 * 
 * @param {*} time 
 * @returns 
 */
export const formatTime = (time) => {
  return time ? moment(time).format("YYYY-MM-DD h:mm:ss") : time
}
