/**
 * Created by yelan on 2018/10/31.
 */
import axios from 'axios';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { STATUS_SUCCESS } from 'MyConstants';
import { HOST } from '@/host'
import { message } from 'antd';

const ACTION_FETCH_META_DATA = 'STRATEGY/FETCH_META_DATA';
const ACTION_FETCH_BUY_SELL_TYPES_DATA = 'STRATEGY/FETCH_BUY_SELL_TYPE_DATA';
/*------------------------actions-------------------------------*/
const strategyAction = (type, result) => ({
    type,
    result
});

export const fetchStrategyMeta = () =>
    async (dispatch) => {
        const { data } = await axios.get(HOST + "/api/findAllStrategyMetas");
        dispatch(strategyAction(ACTION_FETCH_META_DATA, { strategyMetaDataList: data }));
    };

export const fetchBuySellTypes = () =>
    async (dispatch) => {
        const { data } = await axios.get(HOST + '/api/getBuySellTypes');
        dispatch(strategyAction(ACTION_FETCH_BUY_SELL_TYPES_DATA, { buySellTypeList: data }));
    }

/*------------------------actions: create strategy-------------------------------*/
export const createStrategy = (data) =>
    async (dispatch, getState) => {
        // todo 确保数据无误
        if (!validateMetaId(data.metaId, getState().strategyReducer.strategyMetaDataList)) {

            return;
        }
        dispatch(showLoading());
        await axios.post(HOST + "/api/createStrategy", data, {
            headers: {
                'content-type': 'application/json'
            }
        });
        dispatch(hideLoading());
    };

/*------------------------actions: update strategy-------------------------------*/
export const updateStrategy = (data) =>
    async (dispatch, getState) => {
        // todo 确保数据无误
        if (!validateMetaId(data.metaId, getState().strategyReducer.strategyMetaDataList)) {
            return;
        }
        dispatch(showLoading());
        await axios.post(HOST + "/api/updateStrategy", formatStrategyData(data), {
            headers: {
                'content-type': 'application/json'
            }
        })
        dispatch(hideLoading());
    };

const formatStrategyData = (data) => {
    if (data.metaId === 10) {
        const argsObjArray = JSON.parse(data.args);
        let copyDataSource = [...argsObjArray];
        copyDataSource.forEach(item => item.args = JSON.stringify(JSON.parse(item.args)));
        data.args = JSON.stringify(copyDataSource);
    }
    return data;
}

const validateMetaId = (metaId, strategyMetaDataList) => {
    const valid = strategyMetaDataList.some((data) => {
        return data.id === metaId;
    })
    if (!valid) {
        message.error('策略ID不正确，请重新选择！', 2);
    }
    return valid;
}
/*------------------------reducer-------------------------------*/
const stateInitData = {
    strategyMetaDataList: [],
    buySellTypeList: []
};

const reducer = (state = stateInitData, action) => {
    switch (action.type) {
        case ACTION_FETCH_BUY_SELL_TYPES_DATA:
            return {
                ...state, status: STATUS_SUCCESS,
                ...action.result
            }
        case ACTION_FETCH_META_DATA: {
            return {
                ...state, status: STATUS_SUCCESS,
                ...formatMetaData(action.result)
            }
        }
        default: {
            return state;
        }
    }
}

const formatMetaData = ({ strategyMetaDataList }) => {
    const formatData = strategyMetaDataList.map(item => {
        if (item.id === 10) {
            item.argsMeta = '[]';
        }
        return item;
    })
    return { strategyMetaDataList: formatData }
}

export default reducer
