import { useState, useEffect, useCallback } from "react";
import { Layout } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { getUser, getProfile } from "@/api/common";
import MenuPanel from "./component/MenuPanel";
import GlobalModal from "./component/GlobalModal";

const { Sider, Content } = Layout;
const profile_style = { fontSize: "15px", fontWeight: 500 };

const BasicLayout = (props) => {

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        {/* 左侧菜单导航栏 */}
        <MySideBar />
        <Layout>
          <Content style={{ position: "relative" }}>
            {props.children}
          </Content>
        </Layout>
      </Layout>
      <GlobalModal />
    </>
  );
};

/**
 * Logo 组件
 * @returns 
 */
const Logo = () => {
  const [profile, setProfile] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getProfile();
      setProfile(data);
    };

    fetchData();
  }, []);

  const live_color =
    profile === "live" ? { color: "red" } : { color: "lightblue" };

  return <div className="logo">
    Robot{" "}
    <span style={{ ...profile_style, ...live_color }}>{profile}</span>
  </div>
}

/**
 * 菜单组件
 * @returns 
 */
const MySideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getUser();
      setUserName(data.name);
    };

    fetchData();
  }, []);

  const onCollapse = useCallback((collapsed) => {
    setCollapsed(collapsed);
  }, []);


  return <Sider
    width={150}
    collapsible
    collapsed={collapsed}
    onCollapse={onCollapse}
  >
    <Logo />
    <div className="usename">
      <UserOutlined />
      &nbsp;
      {userName}
    </div>
    <MenuPanel />
  </Sider>
}

export default BasicLayout;
