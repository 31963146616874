import { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "antd";
import CalcPipCount from "../../component/calculator/CalcPipCount";
import CalcPrice from "../../component/calculator/CalcPrice";

const GlobalModal = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const onKeyDown = useCallback((e) => {
    // ctrl+Q
    if (e.keyCode === 81 && e.ctrlKey) {
      setModalVisible(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <Modal
      title="计算器"
      visible={isModalVisible}
      closable={false}
      maskClosable={false}
      footer={
        <Button key="close" onClick={() => setModalVisible(false)}>
          关闭
        </Button>
      }
      width={1000}
    >
      <CalcPipCount />
      <br />
      <CalcPrice />
    </Modal>
  );
};

export default GlobalModal;
