import axios from 'axios';
import { HOST } from '@/host'

const ACTION_FETCH_TAG_SUCCESS = 'STOCK/FETCH_TAG_SUCCESS';

const fetchTagsAction = (type, result) => ({
    type,
    result
});

export const fetchTags = () => {
    return (dispatch) => {

        return axios.get(HOST + '/api/findAllTags')
            .then((response) => {
                dispatch(fetchTagsAction(ACTION_FETCH_TAG_SUCCESS, {tags: response.data}))
            });
    }
};

const reducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_FETCH_TAG_SUCCESS:
            return {...state, ...action.result};
        default:
            return state;
    }
}

export default reducer