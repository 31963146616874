import axios from "axios";
import { HOST } from "@/host";

export const getInstruments = () => {
  return axios.get(HOST + "/api/live/getInstruments");
};

export const getInstrumentsWithMinAmount = () => {
  return axios.get(HOST + "/api/live/getInstrumentsWithMinAmount");
};

export const getDefaultConfs = (instrument) => {
  if (instrument) {
    return axios.get(HOST + `/api/live/getDefaultConfs?instrument=${instrument}`);
  }
  return axios.get(HOST + "/api/live/getDefaultConfs");
};

export const getInfo = () => {
  return axios.get(HOST + "/api/live/info");
}

export const getStartedStrategies = () => {
  return axios.get(HOST + `/api/live/getStartedStrategies`);
}

export const findAllOrders = () => {
  return axios.get(HOST + `/api/live/findAllOrders`);
}

export const getAllOrders = (confId) => {
  return axios.get(HOST + `/api/live/getAllOrders`, {
    params: { confId },
  });
}

export const getConditions = (confId) => {
  return axios.get(HOST + `/api/live/getConditions`, {
    params: { confId },
  });
}

export const getAccountInfo = ()=>{
  return axios.get(HOST + `/api/live/account`);
}

export const getStrategyMetada = ()=>{
  return axios.get(HOST + `/api/live/getStrategyMetada`);
}

export const showRiskInfo = (confId)=>{
  return axios.get(HOST + `/api/live/getTrendMartinSL?confId=${confId}`);
}

export const getStrategies = ()=>{
  return axios.get(HOST + `/api/live/getStrategies`);
}

export const doCheckIn = () => {
  return axios.get(HOST + '/api/live/checkin')
}