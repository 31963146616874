import React from "react";

import BasicLayout from "@/layouts/BasicLayout";
import UserLayout from "@/layouts/UserLayout";

const HomeView = React.lazy(() => import("@/pages/index"));

const DashboardView = React.lazy(() => import("@/pages/DashboardView"));
const StrategyView = React.lazy(() => import("@/pages/StrategyView"));
const CalendarView = React.lazy(() => import("@/pages/calendar/CalendarView"));
const OthersView = React.lazy(() => import("@/pages/OthersView"));
const CalculateView = React.lazy(() =>
  import("@/pages/calculate/CalculateView")
);
const EAView = React.lazy(() => import("@/pages/EAView"));
const EAReviewView = React.lazy(() => import("@/pages/EAReviewView"));
const EAQuizView = React.lazy(() => import("@/pages/EAQuizView"));
const BullStockView = React.lazy(() =>
  import("@/pages/bullstock/BullStockView")
);
const BullStockDetailView = React.lazy(() =>
  import("@/pages/bullstock/BullStockDetailView")
);
const NotFound = React.lazy(() => import("@/pages/NotFound"));
const LoginView = React.lazy(() => import("@/pages/login/Login"));
const StockSelectionView = React.lazy(() =>
  import("@/pages/StockSelection/StockSelectionView")
);
const ResearchView = React.lazy(() => import("@/pages/Research"));
const Retro = React.lazy(() => import("@/pages/Retro"));
const EaCopier = React.lazy(() => import("@/pages/EaCopier"));

const routerConfig = [
  {
    path: "/user",
    component: UserLayout,
    children: [
      {
        path: "/login",
        component: LoginView,
      },
      {
        path: "/stocks",
        component: StockSelectionView,
      },
      {
        path: "/",
        redirect: "/user/login",
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    path: "/",
    component: BasicLayout,
    children: [
      {
        path: "/dashboard",
        component: DashboardView,
      },
      {
        path: "/strategy",
        component: StrategyView,
      },
      {
        path: "/calendar",
        component: CalendarView,
      },
      {
        path: "/ea",
        component: EAView,
      },
      {
        path: "/review",
        component: EAReviewView,
      },
      {
        path: "/quiz",
        component: EAQuizView,
      },
      {
        path: "/market/:id",
        component: BullStockDetailView,
      },
      {
        path: "/market",
        component: BullStockView,
      },
      {
        path: "/calculate",
        component: CalculateView,
      },
      {
        path: "/others",
        component: OthersView,
      },
      {
        path: "/research",
        component: ResearchView,
      },
      {
        path: "/retro",
        component: Retro,
      },
      {
        path: "/eacopier",
        component: EaCopier,
      },
      {
        path: "/",
        redirect: '/ea',
      },
      {
        component: NotFound,
      },
    ],
  },
];

export default routerConfig;
