/**
 * Created by yelan on 2018/8/16.
 */

import { Provider } from "react-redux";
import store from "./Store";

import MyRouter from "./MyRouter";
import ErrorHandler from "./hoc/ErrorHandler";
import "./util/axios";

const App = () => {

  return (
    <Provider store={store}>
      <ErrorHandler>
        <MyRouter />
      </ErrorHandler>
    </Provider>
  );
};

export default App;
