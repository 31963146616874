/**
 * Created by yelan on 2018/10/31.
 */
import _ from "lodash";
import axios from "axios";
import { STATUS_LOADING, STATUS_SUCCESS } from "MyConstants";
import { HOST } from "@/host";
import { showLoading, hideLoading } from "react-redux-loading-bar";

const ACTION_FETCH_STRATEGY_SUCCESS = "STOCK/FETCH_STRATEGY_SUCCESS";
const ACTION_TOGGLE_STRATEGY_SUCCESS = "STOCK/TOGGLE_STRATEGY_SUCCESS";

/*------------------------actions: fetch strategy-------------------------------*/
export const fetchStockStrategies = (tag) => async (dispatch) => {
  dispatch(showLoading());
  await findAllStrategies(dispatch, tag);
  dispatch(hideLoading());
};

/*------------------------actions: delete strategy-------------------------------*/
export const deleteStrategy = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  await axios.delete(HOST + "/api/deleteStrategy?id=" + id);
  await findAllStrategies(dispatch, getState().stockReducer.filterTag, true);
  dispatch(hideLoading());
};

// handle findAllStrategies api
const findAllStrategies = async (dispatch, tag) => {
  let { data } = await axios.get(HOST + "/api/findAllStrategies", {
    params: { tags: tag || "" },
  });

  dispatch(
    fetchStockStrategiesAction(ACTION_FETCH_STRATEGY_SUCCESS, {
      stocks: data,
      filterTag: tag,
    })
  );
};

/*------------------------actions: update strategy-------------------------------*/
export const toggleStrategy = (data) => async (dispatch) => {
  dispatch(showLoading());
  await axios.post(HOST + "/api/updateStrategy", data, {
    headers: {
      "content-type": "application/json",
    },
  });
  dispatch(fetchStockStrategiesAction(ACTION_TOGGLE_STRATEGY_SUCCESS));
  dispatch(hideLoading());
};

/*------------------------actions-------------------------------*/
const fetchStockStrategiesAction = (type, result) => ({
  type,
  result,
});

/*-------------------------------reducer---------------------------------------*/
const initialData = {
  status: STATUS_LOADING,
  stockList: [],
  filterTag: "",
  error: "", // todo
};
const reducer = (state = initialData, action) => {
  switch (action.type) {
    case ACTION_FETCH_STRATEGY_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
        filterTag: action.result.filterTag,
        ...getResultData(action.result),
      };
    }
    case ACTION_TOGGLE_STRATEGY_SUCCESS: {
      return { ...state, status: STATUS_SUCCESS };
    }
    default: {
      return state;
    }
  }
};

const getResultData = ({ stocks, filterTag }) => {
  let data = formatStockList(stocks);
  // stock card list
  return {
    stockList: data.stockList,
    watchedStock: data.watchedStock,
    filterTag,
  };
};

const formatStockList = (stocks) => {
  let stockList = [];
  let watchedStock = 0;
  stocks.forEach(function (stockObj) {
    let stock = stockObj.stock;
    stock.metaStrategyDesc = "";
    stock.stockTagList = [];
    stock.enable = false;
    stock.strategyList = [];
    stockObj.strategies
      .sort(function (s1, s2) {
        return s2.enable - s1.enable;
      })
      .forEach(function (strategy) {
        strategy.tagList = strategy.tag ? strategy.tag.split(" ") : [];

        strategy.enable && (stock.enable = true);
        // handle Args
        strategy.argsStr = formatArgs(strategy);
        // handle tags
        stock.stockTagList = stock.stockTagList.concat(strategy.tagList);
        // add strategy
        stock.strategyList.push(strategy);
      });

    stock.stockTagList = _.union(stock.stockTagList);
    if (stock.enable) {
      watchedStock++;
    }
    stockList.push(stock);
  });

  return {
    watchedStock: watchedStock,
    stockList: stockList.sort(function (stock1, stock2) {
      return stock2.enable - stock1.enable;
    }),
  };
};

function formatArgs(strategy) {
  let argsObj = JSON.parse(strategy.args);
  if (_.isArray(argsObj)) {
    return argsObj
      .map((item) => {
        return parseObj(item);
      })
      .join("\n");
  } else {
    return parseObj(argsObj);
  }
}

function parseObj(obj) {
  let argsArray = [];
  _.forOwn(obj, function (key, value) {
    argsArray.push(value + ": " + key + "\n");
  });
  return argsArray.join("");
}

export default reducer
