import { Spin } from 'antd';
const Loading = () => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            display: 'flex',
            background: 'rgba(0,0,0,0.1)',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
             <Spin size="large" />
        </div>
    )
}

export default Loading