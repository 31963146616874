import {
  DesktopOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  TransactionOutlined,
  MonitorOutlined,
  FileDoneOutlined,
  FormOutlined,
  FireOutlined,
  FundOutlined,
  AccountBookOutlined,
  SettingOutlined,
} from "@ant-design/icons";

export const menuMap = {
  //监控策略
  stra_list: {
    path: "/dashboard",
    icon: <DesktopOutlined />,
  },
  //创建策略
  create_stra: {
    path: "/strategy",
    icon: <AppstoreOutlined />,
  },
  //操作日历
  operation: {
    path: "/calendar",
    icon: <CalendarOutlined />,
  },
  //EA交易
  ea_trades: {
    path: "/ea",
    icon: <TransactionOutlined />,
  },
  //EA Copier
  ea_copier: {
    path: "/eacopier",
    icon: <TransactionOutlined />,
  },
  //EA复盘
  ea_review: {
    path: "/review",
    icon: <MonitorOutlined />,
  },
  //EA测验
  ea_quiz: {
    path: "/quiz",
    icon: <FileDoneOutlined />,
  },
  //复盘
  retro: {
    path: "/retro",
    icon: <FormOutlined />,
  },
  //原EA行情，改名叫案例
  bull_stock: {
    path: "/market",
    icon: <FireOutlined />,
  },
  //研报
  research: {
    path: "/research",
    icon: <FundOutlined />,
  },
  //计算器
  calculator: {
    path: "/calculate",
    icon: <AccountBookOutlined />,
  },
  //其他
  others: {
    path: "/others",
    icon: <SettingOutlined />,
  },
};
