import { Layout } from "antd";

const UserLayout = (props) => {
  return (
    <Layout>
      {/* 可能是登录页，或者注册页 */}
      {props.children}
    </Layout>
  );
};

export default UserLayout;
