import { useCallback } from "react";
import { Form, Input, Button } from "antd";
import { calcCurrentPrice } from "../../api/common";

const CalcPrice = () => {
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();
    const { data } = await calcCurrentPrice(values);
    const { percent, destUp, destDown, profit, stockNum } = data;
    form.setFieldsValue({
      percent,
      destUp,
      destDown,
      profit, 
      stockNum
    });
  }, [form]);

  return (
    <>
      <h4>价格计算器</h4>
      <Form
        form={form}
        layout="inline"
        onFinish={handleSubmit}
        initialValues={{}}
      >
        <Form.Item
          label="当前价"
          name="current"
          rules={[{ required: true, message: "请输入当前价！" }]}
        >
          <Input allowClear/>
        </Form.Item>
        <Form.Item label="涨跌百分比" name="percent">
          <Input allowClear/>
        </Form.Item>
        <Form.Item label="上涨目标价" name="destUp">
          <Input allowClear/>
        </Form.Item>
        <Form.Item label="下跌目标价" name="destDown">
          <Input allowClear/>
        </Form.Item>
        <Form.Item label="涨跌金额" name="profit">
          <Input allowClear/>
        </Form.Item>
        <Form.Item label="股数" name="stockNum">
          <Input allowClear/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            计算
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CalcPrice;
