import axios from "axios";
import { message as Message } from "antd";

axios.defaults.timeout = 20000;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    const { code, data, message } = response?.data;
    if (code === 200) {
      return { data, status: code };
    }
    else {
      Message.error(message || "请求错误！");
      return Promise.reject(response);
    }
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.location.hash = "user/login";
      return Promise.reject(error);
    }
    const err = error ? error.response : "error";
    Message.error(
      err && err.data && err.data.message ? err.data.message : "请求错误！"
    );
    return Promise.reject(err);
  }
);
