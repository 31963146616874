import { useEffect, useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { getNavList } from "@/api/common";

import { menuMap } from "@/config/menu";

const MenuPanel = () => {

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getNavList();
      const list = data
        .filter((item) => item.show && menuMap[item.iden])
        .map((item) => {
          const { path } = menuMap[item.iden];
          return { ...item, path };
        });
      setMenuList(list);
    };

    fetchData();
  }, []);

  function getActiveItemKey() {
    return `/${window.location.hash.match(/#\/(\S*)/)[1]}`;
  }

  return (
    <Menu theme="dark" selectedKeys={getActiveItemKey()} mode="inline">
      {menuList.map((item) => {
        return (
          <Menu.Item key={item.path} icon={item.icon}>
            <NavLink to={item.path} activeClassName="link-active">
              <span>{item.label}</span>
            </NavLink>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default MenuPanel;
