/**
 * Created by yelan on 2018/9/4.
 */

import { createStore, compose, applyMiddleware } from 'redux';
import { loadingBarMiddleware, loadingBarReducer } from 'react-redux-loading-bar';

import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk'
import stockReducer from './redux/dashboardRedux';
import tagReducer from './redux/tagRedux';
import strategyReducer from './redux/strategyRedux';
import noteReducer from './redux/calendarRedux';
import eaLiveReducer from './redux/eaLiveRedux';
import eaConfigReducer from './redux/eaConfigRedux'
import eaInstrumentsReducer from './redux/eaInstruments'

let reducers = combineReducers({
    loadingBar: loadingBarReducer,
    stockReducer,
    tagReducer,
    strategyReducer,
    noteReducer,
    eaLiveReducer,
    eaConfigReducer,
    eaInstrumentsReducer,
});

const middlewares = [thunkMiddleware, loadingBarMiddleware()];
//https://github.com/zalmoxisus/redux-devtools-extension#usage
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeEnhancers = composeEnhancers(
    applyMiddleware(...middlewares),
);
//createStore(reducer, [initialState], enhancer)
let store = createStore(reducers, {}, storeEnhancers);

export default store;
