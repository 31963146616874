import axios from 'axios';
import { HOST } from '@/host'
import { message } from 'antd'

const ACTION_FETCH_EA_INSTRUMENTS_SUCCESS = 'EA/FETCH_EA_INSTRUMENTS_SUCCESS';

const fetchEAAction = (type, result) => ({
    type,
    result
});

export const getSubscribedInstruments = () =>
    async (dispatch) => {
        dispatch(fetchEAAction(ACTION_FETCH_EA_INSTRUMENTS_SUCCESS, { loading: true }))
        const response = await axios.get(HOST + `/api/live/getSubscribedInstruments`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_INSTRUMENTS_SUCCESS, { subscribedInstrumentList: response.data, loading: false }))
    }


export const subscribeInstruments = (instrument) =>
    async (dispatch) => {
        await axios.post(HOST + `/api/live/subscribeInstruments?instrument=${instrument}`, {})
        message.success('成功subscribeInstruments！', 1)
        const response = await axios.get(HOST + `/api/live/getSubscribedInstruments`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_INSTRUMENTS_SUCCESS, { subscribedInstrumentList: response.data }))
    }

export const unsubscribedInstruments = (instrument) =>
    async (dispatch) => {
        await axios.post(HOST + `/api/live/unsubscribedInstruments?instrument=${instrument}`, {})
        message.success('成功unsubscribedInstruments！', 1)
        const response = await axios.get(HOST + `/api/live/getSubscribedInstruments`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_INSTRUMENTS_SUCCESS, { subscribedInstrumentList: response.data }))
    }

const defaultValue = {
    subscribedInstrumentList: [],
    loading: false
}

const reducer = (state = defaultValue, action) => {
    switch (action.type) {
        case ACTION_FETCH_EA_INSTRUMENTS_SUCCESS:
            return { ...state, ...action.result };
        default:
            return state;
    }
}

export default reducer