/**
 * Created by yelan on 2018/10/1.
 */
import React, { Suspense } from 'react';
import path from 'path';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Loading from '@/component/Loading';
import ErrorBoundary from '@/component/ErrorBoundary';
import routes from './config/routes';

const RouteItem = (props) => {
    const { redirect, path: routePath, component, key } = props;
    
    if (redirect) {
        return (
            <Redirect
                exact
                key={key}
                from={routePath}
                to={redirect}
            />
        );
    }
    return <Route
        key={key}
        component={component}
        path={routePath}
    />;
};


const MyRouter = () => {

    return (
        <Router>
            <Switch>
                {
                    routes.map((route, id) => {
                        const { component: RouteComponent, children, ...others } = route;

                        return (
                            <Route
                                key={id}
                                {...others}
                                component={props => {
                                    return (
                                        children ? (
                                            <RouteComponent key={id} {...props}>
                                                <ErrorBoundary>
                                                    <Suspense fallback={<Loading />}>
                                                        <Switch>
                                                            {children.map((routeChild, idx) => {
                                                                const { path: childPath, ...rest } = routeChild;
                                                                return RouteItem({
                                                                    key: `${id}-${idx}`,
                                                                    path: childPath && path.join(route.path, childPath),
                                                                    ...rest
                                                                })
                                                            })}
                                                        </Switch>
                                                    </Suspense>
                                                </ErrorBoundary>
                                            </RouteComponent>
                                        ) : (
                                                <ErrorBoundary>
                                                    <Suspense fallback={<Loading />}>
                                                        {
                                                            RouteItem({
                                                                key: id,
                                                                ...route
                                                            })
                                                        }
                                                    </Suspense>
                                                </ErrorBoundary>
                                            )
                                    )
                                }}
                            ></Route>
                        )
                    })
                }
            </Switch>
        </Router>
    )
}

export default MyRouter
