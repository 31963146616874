import axios from 'axios';
import { HOST } from '@/host'
import { message } from 'antd'

const ACTION_FETCH_EA_DB_CONFIG_SUCCESS = 'EA/FETCH_EA_DB_CONFIG_SUCCESS';

const fetchEAAction = (type, result) => ({
    type,
    result
});

export const getAllDbConfs = () =>
    async (dispatch) => {
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { loading: true }))
        const response = await axios.get(HOST + `/api/live/getAllDbConfs`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { configList: response.data, loading: false }))
    }

export const getInstruments = () =>
    async (dispatch) => {
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { loading: true }))
        const response = await axios.get(HOST + `/api/live/getInstruments`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { instrumentList: response.data, loading: false }))
    }

export const createLiveConf = (strategyConfigVo) =>
    async (dispatch) => {
        await axios.post(HOST + `/api/live/createLiveConf`, { ...strategyConfigVo })
        message.success('成功创建配置！', 1)
        const response = await axios.get(HOST + `/api/live/getAllDbConfs`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { configList: response.data }))
    }

export const updateLiveConf = (strategyConfigVo) =>
    async (dispatch) => {
        await axios.post(HOST + `/api/live/updateLiveConf`, { ...strategyConfigVo })
        message.success('成功创建配置！', 1)
        const response = await axios.get(HOST + `/api/live/getAllDbConfs`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { configList: response.data }))
    }

export const deleteLiveConf = (id) =>
    async (dispatch) => {
        await axios.post(HOST + `/api/live/deleteLiveConf?id=${id}`, {})
        message.success('成功删除配置！', 1)
        const response = await axios.get(HOST + `/api/live/getAllDbConfs`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { configList: response.data }))
    }

export const duplicateConf = (id) =>
    async (dispatch) => {
        await axios.post(HOST + `/api/live/duplicateLiveConf?id=${id}`, {})
        message.success('成功复制配置！', 1)
        const response = await axios.get(HOST + `/api/live/getAllDbConfs`);
        dispatch(fetchEAAction(ACTION_FETCH_EA_DB_CONFIG_SUCCESS, { configList: response.data }))
    }


const defaultValue = {
    configList: [],
    instrumentList: [],
    loading: false
}

const reducer = (state = defaultValue, action) => {
    switch (action.type) {
        case ACTION_FETCH_EA_DB_CONFIG_SUCCESS:
            return { ...state, ...action.result };
        default:
            return state;
    }
}

export default reducer