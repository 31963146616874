import React from 'react';

class ErrorHandler extends React.Component {
    constructor(){
        super(...arguments);
        this.state = {error: false};
    }

    componentDidCatch(error, info){
        this.setState({error, info});
    }

    render(){
        if (this.state.error){
            return (<div>{this.state.info}</div>);
        }
        return this.props.children;
    }
}

export default ErrorHandler;